@tailwind base;
@tailwind components;
@tailwind utilities;

/* Colors for Default Dark Mode */
:root {
  --first: #0D2438;
  --second: #008080;
  /* --third: #1E3851; */
  --third: #2F4F4F;
  --fourth: #FFFFFF;
  --white: #FFFFFF;
}

/* Font Families and Colors */
.roboto {
  font-family: 'Roboto', sans-serif;
}

.scp {
  font-family: 'Source Code Pro', monospace;
}

.text-first {
  color: var(--first);
}

.text-second {
  color: var(--second);
}

.text-third {
  color: var(--third);
}

.text-fourth {
  color: var(--fourth);
}

.text-white {
  color: var(--white);
}

/* Background Colors */
.bg-first {
  background-color: var(--first);
}

.bg-second {
  background-color: var(--second);
}

.bg-third {
  background-color: var(--third);
}

.bg-fourth {
  background-color: var(--fourth);
}

/* Blending for Timeline Logos */

.blend-mode {
  mix-blend-mode: multiply;
}