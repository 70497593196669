@tailwind base;
@tailwind components;
@tailwind utilities;

/* Default Options */
body, html {
    height: 100%;
    margin: 0px;
    overflow: auto;
}

html {
    height: 100%;
    margin: 0px;
    overflow: hidden;
}

/* Start Header Portion here */

.nav-text {
    color: var(--fourth);
}

.hideMenuNav {
    display: none;
}

.showMenuNav {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

/* Start Home Portion Here */

#homeBackground {
    background-color: var(--second);
}